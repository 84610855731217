import 'vue-select/dist/vue-select.css';
import axios from "axios"
//import { component as VueNumber } from '@coders-tm/vue-number-format'
//import { directive as VNumber } from '@coders-tm/vue-number-format'
//import { ref } from 'vue';
import {useToast} from 'vue-toast-notification';
//import vSelect from 'vue-select'

import 'vue-toast-notification/dist/theme-bootstrap.css';
import "admin-lte"
import "bootstrap"
import "jquery"
//import { computed } from 'vue';

const $toast = useToast();
const backend_data=window.backend_data

export const program_show_app={
    data() {
        return {
            area_power:{},
            prueba:"MASA",
            care_diag_form:backend_data.care_diag_form,
            pdt_form:backend_data.pdt_form,
            files:{},
            spinners:{sendfile:false},
            buscadores: { municipios: { data: { results: [] } }, ciiu: { data: { results: [] } }, paises: { data: { results: [] } } },

        }   
    },
    methods: {
        coincidencia_helper:function(c){
            if(c>=0 && c<25){
                return {bg:"bg-white",label:"Incipiente"}
            } else if(c>=25 && c<50){
                return {bg:"bg-yellow",label:"Inicial"}
            } else if(c>=50 && c<75){
                return {bg:"bg-orange",label:"Estandarización"}
            } else if(c>=75 && c<=100){
                return {bg:"bg-green",label:"Mejora Continua"}
            }
        },
        openform:function(form){
            $(`#modal_${form}`).modal("show")
        },
        send_form_diag:function(){
            axios.post("/programs/"+backend_data.program_id+"/send_to_brain_diag_json/",{fields:this.care_diag_form,authenticity_token:backend_data.authenticity_token}).then(res=>{
                $toast.success("Actualizado, Se revisará el plan de trabajo de nuevo",{position:"top-right",duration:5000});
                this.set_flag_program('review_diag',true)
                location.reload()
            }).catch(e=>{
                $toast.error("Eroror:" +e,{position:"top-right",duration:5000});
                })
        },
        send_form_pdt:function(){
            axios.post("/programs/"+backend_data.program_id+"/send_to_brain_pdt_json/",{fields:this.pdt_form,authenticity_token:backend_data.authenticity_token}).then(res=>{
                $toast.success("Actualizado, Se enviara para reentrenamiento",{position:"top-right",duration:5000});
                this.set_flag_program('pdt_review',true)
                location.reload()
            }).catch(e=>{
                $toast.error("Eroror:" +e,{position:"top-right",duration:5000});
                })
        },
        set_flag_program: function(flag, value){
            let flags = {}
            flags[flag] = value
            axios.patch("/programs/"+backend_data.program_id+"/set_flags",{flags:flags,authenticity_token:backend_data.authenticity_token})
            .then(res => {
                console.log('la respuesta es ', res);
            }).catch(error => {
                console.log('the error is ', error);
            })
        },
        fileupload: function(event, type,program_id) {
            let name = "doc_" + type;
            this.files[name] = event.target.files[0];
            console.log('Selected file:', this.files[name]);
            this.filesend(program_id, type, this.files[name].name);
        },
        delete_file:function(id){
            axios.delete("/docs/"+id+".json",{data:{authenticity_token:backend_data.authenticity_token}}).then(res=>{
                location.reload();
            })
        },
        filesend: function(program_id, type, filename) {
            this.spinners.sendfile = true;
            let formData = new FormData();
            formData.append('file', this.files["doc_" + type]);
            formData.append('type', type);
            formData.append('description', "");
            formData.append('user_id', backend_data.user_id);
            formData.append('company_id', backend_data.company_id);
            formData.append('program_id', program_id);
            formData.append('filename', filename);
            formData.append('bucket', 'normania-docs');
            formData.append('authenticity_token', backend_data.authenticity_token);
        
            axios.post('/docs', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(res => {
                    console.log('rta',res);
                    this.spinners.sendfile = false;
                    location.reload();
                })
                .catch(err => {
                    this.spinners.sendfile = false;
                    alert('error subiendo archivo', err);
                });
        }

    },
    computed:{
        show_btn_update_diagnosis:function () {
            let value = true
            if (typeof(this.pdt_form) == 'object' && this.pdt_form.length > 0) {
                value = false
            }
            return value
        }
    },
    components:{


    }
}